<template>
  <section class="bg-mint">
    <DefaultGrid class="pb-20 pt-32 md:pb-24 md:pt-48">
      <div
        class="col-span-full flex flex-col items-center space-y-16 md:space-y-36"
      >
        <LockupPledge
          class="text-[90px]/[0.95em] md:text-[175px]/[0.95em] xl:text-[205px]/[0.95em]"
        />
        <div class="flex flex-col items-center space-y-5 md:space-y-12">
          <NuxtLink
            v-for="({ label, to, target, className }, index) in buttons"
            :key="index"
            class="btn btn-lg"
            :class="className"
            :to="to"
            :target="target"
          >
            {{ label }}
          </NuxtLink>
        </div>
      </div>
    </DefaultGrid>
  </section>
</template>

<script setup lang="ts">
const buttons = [
  {
    label: 'Okay, let’s do it!',
    to: '/pledge',
    className: 'btn-yellow',
  },
  {
    label: 'Follow @wait.mate',
    to: 'https://www.instagram.com/wait.mate',
    target: '_blank',
    className: 'btn-outline',
  },
]
</script>
