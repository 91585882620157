<template>
  <component :is="props.tag" :class="nextClass">
    <div
      :class="$attrs.class"
      class="md:rounded-b-[100px] lg:rounded-b-[125px] xl:rounded-b-[150px]"
    >
      <slot />
    </div>
  </component>
</template>

<script setup lang="ts">
interface PageShingleWrapperProps {
  nextClass: string
  tag?: string
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<PageShingleWrapperProps>(), {
  tag: 'div',
})
</script>
